import clsx from 'clsx'

export default function MapListingCardSkeleton({
  className,
}: {
  className?: string
}) {
  return (
    <div className={clsx(className, 'rounded-md bg-background shadow-md')}>
      <div className="animate-pulse">
        <div className="aspect-video rounded-t-md bg-gray-200 dark:bg-gray-700" />
        <div className="p-4">
          <div className="mb-2 h-6 rounded bg-gray-200 dark:bg-gray-700"></div>
          <div className="mb-1 h-4 w-36 rounded bg-gray-200 dark:bg-gray-700"></div>
          <div className="mb-1 h-4 w-36 rounded bg-gray-200 dark:bg-gray-700"></div>
          <div className="mt-2 flex gap-2">
            <div className="h-6 w-24 rounded bg-gray-200 dark:bg-gray-700" />
            <div className="h-6 w-24 rounded bg-gray-200 dark:bg-gray-700" />
            <div className="h-6 w-24 rounded bg-gray-200 dark:bg-gray-700" />
          </div>
          <div className="mt-2 flex gap-2">
            <div className="h-6 w-24 rounded bg-gray-200 dark:bg-gray-700" />
            <div className="h-6 w-24 rounded bg-gray-200 dark:bg-gray-700" />
          </div>
          <div className="mt-4 flex items-center">
            <svg
              className="me-3 h-10 w-10 text-gray-200 dark:text-gray-700"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 20">
              <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z" />
            </svg>
            <div>
              <div className="h-4 w-48 rounded bg-gray-200 dark:bg-gray-700"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
